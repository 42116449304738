import { Theme, useTheme } from '@mui/material'
import { faChartLine, faChartSimple } from '@fortawesome/free-solid-svg-icons'
import { atomWithHash } from 'jotai-location'
import { useAtom } from 'jotai'
import { formatMoney } from '@/main/utils/functions'
import { Box, Card, Chip, Icon, Skeleton, TabPanel, Typography } from '@/presentation/components/atoms'
import { ButtonSelector, ButtonSelectorOption } from '@/presentation/components/molecules'
import { EarningsBarChart } from './components/earningsBarChart/earningsBarChart'
import { EarningsLineChart } from './components/earningsLineChart/earningsLineChart'
import { YearAndMonth } from '../../dashboard'
import { IUseDashboardReturn } from '../../hooks/useDashboardInterfaces'

interface EarningsChartCardProps {
  data: IUseDashboardReturn['dashboardData']
  selectedDate: YearAndMonth
  todayDate: YearAndMonth
  isLookingActualYear: boolean
  isLoading?: boolean
}
const earningsChartAtom = atomWithHash('earningsChart', 0)
export const EarningsChartCard = ({
  isLookingActualYear,
  data,
  selectedDate,
  todayDate,
  isLoading,
}: EarningsChartCardProps) => {
  const [selectedChart, setSelectedChart] = useAtom(earningsChartAtom)
  const isMonth = selectedDate.month !== ''
  const theme = useTheme()
  if (!data?.monthlyEarnings) return null
  const getInfoFromMonths = ({ prop }: { prop: 'total' | 'growthRate' }): number => {
    if (!data) return
    const selectedMonth = data.monthlyEarnings.find((monthEarning) => monthEarning.month === selectedDate.month)
    return selectedMonth[prop]
  }

  const growthRate = getInfoFromMonths({ prop: 'growthRate' })

  const getRateGrowthLabel = (): string => {
    return `${growthRate > 0 ? '+' : ''}${formatMoney(growthRate)}% /${isMonth ? 'mês' : 'ano'} anterior`
  }

  return (
    <Card sx={{ padding: '16px', minHeight: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='label3' sx={(theme: Theme) => ({ color: theme.palette.grey[700] })}>
          Vendas - {isMonth ? 'mês/' : ''}ano
        </Typography>
        <ButtonSelector value={selectedChart} onChange={setSelectedChart}>
          <ButtonSelectorOption>
            <Icon icon={faChartSimple} />
          </ButtonSelectorOption>
          <ButtonSelectorOption>
            <Icon icon={faChartLine} />
          </ButtonSelectorOption>
        </ButtonSelector>
      </Box>
      {isLoading || !data?.monthlyEarnings ? (
        <Skeleton variant='rounded' height='248px' sx={{ marginTop: '16px' }} />
      ) : (
        <>
          <Box sx={{ display: 'flex', gap: '32px', flexWrap: 'wrap' }}>
            <Typography variant='h2'>
              R$ {formatMoney(getInfoFromMonths({ prop: 'total' }))} -{' '}
              {selectedDate.month !== '' && `${selectedDate.month}/`}
              {selectedDate.year}
            </Typography>
            {isMonth && (
              <Chip
                label={getRateGrowthLabel()}
                shape='pill'
                tagColor={Number(growthRate) > 0 ? theme.palette.success.dark : theme.palette.warning.light}
              />
            )}
          </Box>
          <Typography variant='text2' sx={{ margin: '8px 0', display: 'inline-block' }}>
            O gráfico abaixo mostra o volume e valores em vendas do mês selecionado e de até 12 meses anteriores.
          </Typography>
          <TabPanel index={0} value={selectedChart}>
            <Box sx={{ overflow: 'auto', minHeight: '248px', padding: '8px' }} id='earningsBarChartContainer'>
              <EarningsBarChart
                data={data.monthlyEarnings.slice(0, 12)}
                selectedMonth={selectedDate.month}
                lastActiveMonth={isLookingActualYear ? todayDate.month : null}
              />
            </Box>
          </TabPanel>
          <TabPanel index={1} value={selectedChart}>
            <Box sx={{ overflow: 'auto', minHeight: '248px', padding: '8px' }} id='earningsLineChartContainer'>
              <EarningsLineChart
                data={
                  isLookingActualYear ? data.monthlyEarnings.slice(0, new Date().getMonth() + 1) : data.monthlyEarnings
                }
              />
            </Box>
          </TabPanel>
        </>
      )}
    </Card>
  )
}
