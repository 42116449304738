import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Box, Button, Dialog, DialogActions, DialogContent, Icon, Skeleton, Typography } from '@stationkim/front-ui'

interface ParameterDialogEditionProps {
  onConfirm?: () => void
  onCancel?: () => void
  open?: boolean
  isLoading?: boolean
}

export const ParameterDialogEdition = ({ open, onCancel, onConfirm, isLoading }: ParameterDialogEditionProps) => {
  return (
    <Dialog open={open}>
      <Icon icon={faTriangleExclamation} sx={{ fontSize: '5rem', color: 'warning.main' }} />
      {isLoading ? (
        <>
          <DialogContent>
            <Typography sx={{ margin: '32px 0' }}>As alterações estão sendo salvas, por favor aguarde.</Typography>{' '}
            <Box sx={{ display: 'flex', gap: '32px' }}>
              <Skeleton variant='rounded' width='50%' height='48px' />
              <Skeleton variant='rounded' width='50%' height='48px' />
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent sx={{ margin: '32px 0' }}>
            <Typography>
              Tem certeza que gostaria de <b>salvar as alterações</b> feitas?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ gap: '16px' }}>
            <Button onClick={onCancel} variant='outlined' sx={{ filter: 'grayscale(100)' }} fullWidth>
              Cancelar
            </Button>
            <Button variant='contained' onClick={onConfirm} fullWidth color='warning'>
              Salvar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
