import { Accordion, styled } from '@mui/material'

export const StyledAccordion = styled(Accordion)(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))
