import { useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Box, Button, MaskedTextField, Skeleton } from '@stationkim/front-ui'
import { ButtonsContainer, StyledAccordion, StyledButton, StyledDivider } from './styles'
import { AdministrativeFee } from './components/administrativeFee/administrativeFee'
import {
  EAdministrativeFeeCalculationType,
  EAdministrativeFeeValueType,
  EBenefitType,
  ECompanyStatus,
  EDeskFeeType,
  EParameterStatus,
} from '@/main/enums'
import { CompanyContext } from '../../consultCompany'
import { IssFee } from './components/issFee/issFee'
import { ParameterDialogEdition } from './components/parameterDialogEdition/parameterDialogEdition'
import { useCompanyParametersMutation } from '../../hooks/useCompanyParametersMutation'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

export type TAdministrativeFee = {
  benefit: EBenefitType
  calculationType: EAdministrativeFeeCalculationType
  valueType: EAdministrativeFeeValueType
  initialRange: string
  finalRange: string
  feeValue: string
  id?: string
}

export type TCompanyParametersForm = {
  successFee: string
  issFee: boolean
  issIncidences: number[]
  administrativeFees: TAdministrativeFee[]
}

const initialValues: TCompanyParametersForm = {
  successFee: '',
  issFee: false,
  administrativeFees: [],
  issIncidences: [],
}

export const Parameters = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const { company, isLoading, isFetching } = useContext(CompanyContext)
  const { editParameters } = useCompanyParametersMutation()
  //so pode ditar iss com empresa aprovada ou aguardando aprovacao
  const isIssAvailable = company?.issInfo?.status === EParameterStatus.Active
  const isWithMigrationError = company.status === ECompanyStatus.MigrationError
  const isIssConfigurationAvailable = [ECompanyStatus.Approved, ECompanyStatus.WaitingApproval].includes(
    company?.status,
  )

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<TCompanyParametersForm>({
    defaultValues: initialValues,
  })

  const issFee = watch('issFee')

  const onSubmit: SubmitHandler<TCompanyParametersForm> = (data) => {
    editParameters.mutate(
      {
        companyId: company.id,
        fees: data.administrativeFees.map((fee) => ({
          id: fee.id,
          benefitType: fee.benefit,
          calculationType: fee.calculationType,
          quantityType: fee.valueType,
          valueType: fee.valueType, //?, todo
          startRange: Number(fee.initialRange),
          endRange: Number(fee.finalRange),
          value: Number(fee.feeValue),
          itemType: EDeskFeeType.administrative,
        })),
        issActive: data.issFee,
        issFee: data.issIncidences.reduce((final, actual) => final + actual, 0),
        slipFee: 0, //endpoint requirement
        successFee: Number(data.successFee),
      },
      {
        onSuccess: () => {
          setIsConfirmationDialogOpen(false)
          queryClient.invalidateQueries({ queryKey: ['company'] })
        },
      },
    )
  }

  const handleCancel = () => {
    if (isDirty) reset()
    else navigate('/empresas')
  }

  const handleCancelParameterEdition = () => setIsConfirmationDialogOpen(false)
  const handleFormSave = handleSubmit(onSubmit)

  useEffect(() => {
    reset({
      ...initialValues,
      successFee: company.successFee,
      issFee: company.issInfo?.isActive,
      issIncidences: company.issInfo?.issIncidences,
      administrativeFees: company.administrativeFees as TAdministrativeFee[],
    })
  }, [company, reset])

  return (
    <Box>
      <form id='company-parameters-form'>
        {isIssAvailable && (
          <>
            <IssFee
              issInfo={company.issInfo}
              isVisible={issFee}
              control={control}
              disabled={!isIssConfigurationAvailable}
              isLoading={isLoading}
            />
            <StyledDivider />
          </>
        )}
        <StyledAccordion defaultExpanded label='Taxas administrativas'>
          <AdministrativeFee control={control} disabled={isWithMigrationError} isLoading={isLoading || isFetching} />
        </StyledAccordion>
        <StyledDivider />
        <StyledAccordion defaultExpanded label='Taxa de sucesso'>
          {isLoading ? (
            <Skeleton variant='rounded' height='40px' />
          ) : (
            <Controller
              control={control}
              name='successFee'
              render={({ field: { onChange, value } }) => {
                return (
                  <MaskedTextField
                    mask={'percentual'}
                    label='Valor'
                    placeholder='00,00 %'
                    fullWidth={false}
                    value={value}
                    onChange={onChange}
                    unmask
                  />
                )
              }}
            />
          )}
        </StyledAccordion>
        <StyledDivider />
        <ButtonsContainer sx={{ gap: isDirty ? '16px' : 0 }}>
          <Button variant='outlined' onClick={handleCancel}>
            {isDirty ? 'Cancelar' : 'Voltar'}
          </Button>
          <StyledButton
            visible={isDirty}
            variant='contained'
            onClick={() => setIsConfirmationDialogOpen(true)}
            disabled={isLoading}
          >
            Salvar
          </StyledButton>
        </ButtonsContainer>
      </form>
      <ParameterDialogEdition
        open={isConfirmationDialogOpen}
        onCancel={handleCancelParameterEdition}
        onConfirm={handleFormSave}
        isLoading={editParameters.isLoading}
      />
    </Box>
  )
}
