import { IOrder } from 'main/interfaces'
import moment from 'moment'
import { ORDER_PAYMENT_TYPE } from 'main/utils/constants'

export const organizeData = ({ data }: { data: any }): IOrder => {
  if (!data) return null
  const rawOrder = data.data

  const organizedData: IOrder = {
    paymentDate: rawOrder.payments?.[0]?.liquidationDate
      ? moment(rawOrder.payments?.[0]?.liquidationDate).format('DD/MM/YYYY')
      : null,
    isFinancedSales: rawOrder?.isFinancedSales,
    orderId: rawOrder.id,
    orderCode: rawOrder.code,
    orderType: rawOrder.orderType,
    orderStatus: rawOrder.status,
    orderOperatorName: rawOrder.operatorName,
    orderDate: moment(rawOrder.orderDate).format('DD/MM/YYYY'),
    totalItems: rawOrder.totalItens || 0,
    usedPaymentsTypes:
      rawOrder.payments.length > 0
        ? rawOrder.payments.map((payment: any) => {
            const entry = Object.entries(ORDER_PAYMENT_TYPE).find((entry) => entry[1] === payment.paymentType)
            return entry ? entry[0] : undefined
          })
        : ['FREE'],
    orderSummary: {
      orderValue: rawOrder.orderSummary.subTotalValue,
      administrativeFee: rawOrder.orderSummary.administrateFee,
      processingFee: rawOrder.orderSummary.processingFee,
      transferFee: rawOrder.orderSummary.transferFee,
      issFee: rawOrder.orderSummary.issFee,
      ticketingBalance: rawOrder.orderSummary.ticketingBalance,
      walletUsage: rawOrder.orderSummary.walletUsage,
      orderTotal: rawOrder.orderSummary.subTotalOrder,
      totalPaid: rawOrder.orderSummary.totalAmount,
    },
    slip: !rawOrder.slip
      ? null
      : {
          slipLink: rawOrder.slip.slipLink,
          barcodeNumber: rawOrder.slip.digitableLine,
          slipDueDate: rawOrder.slip.dueDate ? moment(rawOrder.slip.dueDate).format('DD/MM/YYYY') : '',
        },
    destinationAddress: !rawOrder.delivery
      ? null
      : {
          cep: rawOrder.delivery.cep,
          city: rawOrder.delivery.city,
          laneName: rawOrder.delivery.laneName,
          number: rawOrder.delivery.number,
          state: rawOrder.delivery.state,
          deliveryCode: rawOrder.delivery.deliveryCode,
        },
    cart: !rawOrder.basket
      ? null
      : {
          cartOrders: rawOrder.basket.items.map((order: any) => ({
            code: order.code,
            id: order.id,
            status: order.status,
            totalItems: order.totalItems,
            totalValue: order.totalValue,
            type: order.orderType,
            operatorName: order.operatorName,
          })),
          cartSummary: {
            firstCopy: rawOrder.basket.summary.firstCopyValue,
            rechargeValue: rawOrder.basket.summary.rechargeValue,
            secondCopy: rawOrder.basket.summary.secondCopyValue,
            administrativeFee: rawOrder.basket.summary.administrateFee,
            processingFee: rawOrder.basket.summary.processingFee,
            issFee: rawOrder.basket.summary.issFee,
            ticketingBalance: rawOrder.basket.summary.ticketingBalance,
            walletUsage: rawOrder.basket.summary.walletUsage,
            totalPaid: rawOrder.basket.summary.totalAmount,
            transferFee: rawOrder.basket.summary.transferFee,
            totalInOrders: rawOrder.basket.summary.totalOrderValues,
            orderTotal: rawOrder.basket.summary.subTotalOrders,
          },
          id: rawOrder.basket.id,
          code: rawOrder.basket.code,
          hasRecharge: rawOrder.basket.summary.hasRechargeOrder,
          hasFirstCopy: rawOrder.basket.summary.hasFirstCopyOrder,
          hasSecondCopy: rawOrder.basket.summary.hasSecondCopyOrder,
        },
    cancelDate: rawOrder.cancellationDate,
  }
  return organizedData
}
