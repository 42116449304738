import { styled } from '@mui/material'
import { Icon, IconProps } from 'presentation/components/atoms'

interface StyledIconProps extends IconProps {
  backgroundColor: string
}

export const StyledIcon = styled(Icon)<StyledIconProps>(({ theme, color, backgroundColor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '36px',
  width: '36px',
  padding: '14px',
  color: color ?? 'initial',
  backgroundColor: backgroundColor ?? theme.palette.grey[100],
  borderRadius: '50%',
}))

export const PaymentDate = styled('small')`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(97, 97, 97, 1);
`
