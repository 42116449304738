import { useRef, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FileUpload,
  Skeleton,
  Tooltip,
  Typography,
} from '@stationkim/front-ui'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@mui/material'
import { EStatusPedido } from '@/main/enums'
import { ActionIcon } from './styles'
import { useOrderMutations } from '../../../../hooks/useOrderMutations'
import { IOrder } from '../../../../hooks/useOrderList'

interface OrderTreatmentInitProps {
  order: IOrder
}

export const OrderTreatmentInit = ({ order }: OrderTreatmentInitProps) => {
  const theme = useTheme()
  const { uploadProjectionFile, startBrokerOrderProcessing } = useOrderMutations()
  const [isUploadingProjection, setIsUploadingProjection] = useState<boolean>(false)
  const [isAskingConfirmation, setIsAskingConfirmation] = useState<boolean>(false)
  const [file, setFile] = useState<any>() //todo exportar tipagem na front-ui
  const isWaitingProcess = order.orderStatus === EStatusPedido.AguardandoProcessamento
  const shouldHide = order.orderStatus > EStatusPedido.AguardandoProcessamento
  const employeesOnOrder = useRef(0)
  const dialogTitle = isAskingConfirmation ? 'Confirmar o inicio do processamento?' : 'Projeção de saldo'

  const handleUploadProjectionClose = () => {
    if (uploadProjectionFile.isLoading) return
    setIsUploadingProjection(false)
    setIsAskingConfirmation(false)
  }

  const handleTreatmentInit = () => {
    if (order.useBalanceProjection) {
      setIsUploadingProjection(true)
    } else return startBrokerOrderProcessing.mutate({ orderId: order.id })
  }

  const handleDialogConfirm = () => {
    if (isAskingConfirmation)
      startBrokerOrderProcessing.mutate(
        { orderId: order.id, useBalanceProjection: true },
        {
          onSuccess: () => handleUploadProjectionClose(),
        },
      )
    else
      uploadProjectionFile.mutate(
        {
          file,
          companyId: order.companyId,
          orderId: order.id,
          purchaseId: order.purchaseId,
        },
        {
          onSuccess: (res) => {
            employeesOnOrder.current = res?.data?.valor?.quantidade
            setIsAskingConfirmation(true)
          },
        },
      )
  }

  if (shouldHide) return null
  return (
    <>
      <Tooltip title={isWaitingProcess ? 'Iniciar atendimento' : ''} placement='top'>
        <ActionIcon
          icon={faPlay}
          color={theme.palette.success.dark}
          disabled={!isWaitingProcess}
          onClick={handleTreatmentInit}
        />
      </Tooltip>
      {isUploadingProjection && (
        <Dialog open onClose={handleUploadProjectionClose}>
          <DialogTitle sx={{ marginBottom: '16px' }}>{dialogTitle}</DialogTitle>
          <DialogContent>
            {uploadProjectionFile.isLoading ? (
              <Skeleton variant='rounded' height='208px' width='414.5px' />
            ) : isAskingConfirmation ? (
              <>
                <Typography variant='h4' sx={{ margin: '16px 0', fontWeight: 400 }}>
                  Colaboradores com saldo: <b>{employeesOnOrder.current}</b>
                </Typography>
                {employeesOnOrder.current === 0 && (
                  <Typography variant='body2' sx={{ margin: '16px 0', fontWeight: 400 }}>
                    Não foram encontrados colaboradores com saldo.
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography variant='body2' sx={{ marginBottom: '16px' }}>
                  Por favor, envie o arquivo para cálculo de projeção de saldo.
                </Typography>
                <FileUpload
                  accept={{
                    'text/plain': ['.txt'],
                    'application/vnd.ms-excel': ['.xls'],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                  }}
                  onChange={(value) => setFile(value)}
                />
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ marginTop: '16px' }}>
            <Button
              disabled={uploadProjectionFile.isLoading}
              variant='outlined'
              fullWidth
              sx={{ filter: 'grayscale(100)' }}
              onClick={handleUploadProjectionClose}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              fullWidth
              onClick={handleDialogConfirm}
              disabled={!file || uploadProjectionFile.isLoading}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
