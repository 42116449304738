import { Controller, UseFormReturn } from 'react-hook-form'
import { Checkbox, formatMoney, Grid, Skeleton, Typography } from '@stationkim/front-ui'
import { StyledISSContainer, StyledLabel, StyledTypography } from './styles'
import { ICompanyIssFee } from '../../../../hooks/interfaces'
import { TCompanyParametersForm } from '../../parameters'
import { issIncidences } from '@/main/utils/options'
// todo use lib multiselect
import { Multiselect } from '@/presentation/components/organisms'

interface IssFeeProps {
  isVisible: boolean
  issInfo: ICompanyIssFee
  control: UseFormReturn<TCompanyParametersForm>['control']
  disabled?: boolean
  isLoading?: boolean
}

export const IssFee = ({ issInfo, isVisible, control, disabled, isLoading = true }: IssFeeProps) => {
  return (
    <>
      <StyledTypography variant='h4'>Configurações gerais</StyledTypography>

      {isLoading ? (
        <Skeleton variant='rounded' height='40px' />
      ) : (
        <>
          <StyledLabel>
            <Controller
              control={control}
              name='issFee'
              render={({ field: { value, onChange } }) => (
                <Checkbox checked={value} disabled={disabled} onChange={(e) => onChange(e.target.checked)} />
              )}
            />
            <Typography>Retenção ISS</Typography>
          </StyledLabel>
          <StyledISSContainer visible={isVisible}>
            <Grid container alignItems='center' spacing='16px'>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>Cidade</Typography>
                <Typography>{issInfo.city}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography>Valor</Typography>
                <Typography>{formatMoney(Number(issInfo.value))} %</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography>Taxa</Typography>
                <Controller
                  control={control}
                  name='issIncidences'
                  render={({ field: { value, onChange } }) => (
                    <Multiselect value={value} onChange={onChange} options={issIncidences} disabled={disabled} />
                  )}
                />
              </Grid>
            </Grid>
          </StyledISSContainer>
        </>
      )}
    </>
  )
}
