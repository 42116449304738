import { createContext, useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'
import { orderIdAtom } from 'main/store'
import { ORDER_STATUS, ORDER_TYPES } from 'main/utils/constants'
import { useDocumentTitle, useNotifications } from 'main/hooks'
import { IOrder } from 'main/interfaces'
import { useOrderDetail } from './hooks/useOrderDetail'
import { Button, Divider, Grid } from 'presentation/components/atoms'
import { PageHeader, Panel } from 'presentation/components/molecules'
import { PaymentDetail } from './components/paymentDetail/paymentDetail'
import { StatusInformation } from './components/statusInformation/statusInformation'
import { OrderItems } from './components/orderItems/orderItems'
import { OrderTrackingSkeleton } from './components/orderTrackingSkeleton/orderTrackingSkeleton'
import { useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { errorHandler } from '@/main/utils/functions/errorHandler'
import { getDownloadRechargeOrderOperatorSlip, getDownloadRechargeOrderSlip } from '@/services/rechargesServices'
import useService from '@/main/hooks/useService'

interface IOrderTrackingContext extends IOrder {
  isRecharge: boolean
}
export const OrderTrackingContext = createContext<IOrderTrackingContext>({} as IOrderTrackingContext)

export const OrderTracking = () => {
  const documentTile = useDocumentTitle()
  const navigate = useNavigate()
  const shouldBreak = useMediaQuery('@media (max-width:1150px)')
  const orderId = useAtomValue(orderIdAtom)
  const { order, isLoading: isLoadingOrder } = useOrderDetail({
    config: {
      onError: (e) => {
        navigate('/pedidos')
        notify.push(errorHandler(e?.response?.data?.errors))
      },
    },
  })

  const notify = useNotifications()

  const isLoading = isLoadingOrder
  const isRecharge = order?.orderType >= ORDER_TYPES.RECHARGE

  const handleReturn = () => {
    navigate('/pedidos')
  }

  documentTile.override({
    title: !order ? 'Acompanhamento de Pedido' : `Pedido de ${isRecharge ? 'Recarga' : 'Cartão'} #${order?.orderCode}`,
  })

  useEffect(() => {
    if (orderId === '' && !isLoading) handleReturn()
  }, [orderId])

  //todo redo it as soon as possible
  const isBrokerOrder = order?.orderType === ORDER_TYPES.BROKER
  const isInOperatorProcess = order?.orderStatus === ORDER_STATUS.RUNNING

  const [boletoLink, setBoletoLink] = useState(null)
  const [isLoadingSlip, setIsLoadingSlip] = useState(false)
  const [, getSlipRequest] = useService(
    isInOperatorProcess && isBrokerOrder ? getDownloadRechargeOrderOperatorSlip : getDownloadRechargeOrderSlip,
    {
      onCompleted: (response) => {
        setIsLoadingSlip(false)
        setBoletoLink(isInOperatorProcess && isBrokerOrder ? response?.data.temporaryLink : response?.data)
      },
      silent: true,
    },
  )

  useEffect(() => {
    if (orderId && !boletoLink && !isLoadingSlip && order?.slip) {
      if (isBrokerOrder) {
        getSlipRequest(orderId)
        setIsLoadingSlip(true)
      } else if (order?.orderStatus === ORDER_STATUS.WAITING_PAYMENT) {
        getSlipRequest(orderId)
        setIsLoadingSlip(true)
      }
    }
    // eslint-disable-next-line
  }, [orderId, order])

  return (
    <>
      <OrderTrackingContext.Provider value={{ ...order, isRecharge }}>
        <Panel sx={{ padding: '32px', overflow: 'auto' }}>
          <Grid container spacing='32'>
            <Grid item>
              <PageHeader pageTitle='Acompanhamento de Pedido' padding='0' noDivider />
            </Grid>
            <Grid item xs='auto' sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              {isBrokerOrder && order?.orderStatus === ORDER_STATUS.RUNNING && !isLoadingSlip && boletoLink ? (
                <Button variant='contained' onClick={() => window.open(boletoLink)} disabled={!boletoLink}>
                  {isInOperatorProcess && isBrokerOrder ? 'Baixar boleto anexado' : 'Emitir Boleto'}
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Divider sx={{ margin: '32px 0' }} />
          {isLoading || !order ? (
            <OrderTrackingSkeleton />
          ) : (
            <Grid container spacing='32px' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={shouldBreak ? 12 : 8} lg={7}>
                <StatusInformation />
                <OrderItems />
              </Grid>
              <Grid item xs={12} sm={12} md={shouldBreak ? 12 : 4} lg={5}>
                <PaymentDetail />
              </Grid>
              <Grid item xs={12} sm='auto' sx={{ marginLeft: 'auto' }}>
                <Button variant='outlined' onClick={handleReturn}>
                  Voltar
                </Button>
              </Grid>
            </Grid>
          )}
        </Panel>
      </OrderTrackingContext.Provider>
    </>
  )
}
