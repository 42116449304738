import { useContext, useState } from 'react'
import { useSetAtom } from 'jotai'
import { AccountPersistentStore, orderIdAtom } from '@/main/store'
import _ from 'lodash'
import { Theme, useTheme } from '@mui/material'
import { faArrowUpRightFromSquare, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Summary, ValuesSummary } from 'presentation/components/molecules'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Divider,
  Icon,
  NavLink,
  Typography,
} from 'presentation/components/atoms'
import { LinkToOrder, StatusBanner } from './styles'
import { ORDER_STATUS, ORDER_STATUS_KEY, ORDER_TYPES } from 'main/utils/constants'
import { formatMoney } from 'main/utils/functionUtils'
import { ReactComponent as CardIcon } from 'presentation/assets/icons/card.svg'
import { ReactComponent as RechargeIcon } from 'presentation/assets/icons/recharge.svg'
import { orderStatusToColors } from 'main/utils/functions'
import { useAtomWithStorageObserver } from '@/main/hooks'
import { OrderTrackingContext } from '../../../../orderTracking'

export const CartInfo = () => {
  const theme = useTheme()
  const [account] = useAtomWithStorageObserver({ atomWithStorage: AccountPersistentStore, storageName: 'account' })
  const setOrderId = useSetAtom(orderIdAtom)
  const { cart, isRecharge, orderStatus } = useContext(OrderTrackingContext)
  const [open, setOpen] = useState(false)
  const isWaitingPayment = orderStatus < ORDER_STATUS.WAITING_FOR_PROCESSING

  const renderCartOrders = () => {
    const orders = cart?.cartOrders
    return orders?.map((order) => {
      const isOrder = order.type > ORDER_TYPES.SECOND_COPY
      const orderLink = `/pedidos/${isRecharge ? 'recarga' : 'cartao'}#orderId="${order.id}"`
      const orderTitle =
        ORDER_TYPES.FIRST_COPY === order.type
          ? 'Cartão - 1ª via'
          : ORDER_TYPES.SECOND_COPY === order.type
          ? 'Cartão - 2ª via'
          : 'Recarga'
      let orderSubtitle = ''
      const colorScheme = orderStatusToColors({ status: order.status, theme: theme })
      if (order.type > 2)
        orderSubtitle = `${order.operatorName} ⚬ ${order.totalItems} ${
          order.totalItems > 1 ? 'colaboradores' : 'colaborador'
        }`
      else orderSubtitle = `${order.operatorName} ⚬ ${order.totalItems} ${order.totalItems > 1 ? 'cartões' : 'cartão'}`

      const handleLinkClick = ({ orderId }: { orderId: string }) => {
        setOrderId(orderId)
        document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
      }
      return (
        <Card sx={{ padding: '12px', marginTop: '21px' }} key={_.uniqueId()}>
          <StatusBanner color={colorScheme}>
            <Typography variant='h5' sx={{ fontWeight: 500 }}>
              {ORDER_STATUS[order.status as ORDER_STATUS_KEY]}
            </Typography>
          </StatusBanner>
          <Summary
            icon={isOrder ? <RechargeIcon /> : <CardIcon />}
            title={
              <Box sx={{ display: 'flex', gap: '32px', '& a': { color: 'inherit' } }}>
                <Typography variant='h6' sx={{ whiteSpace: 'nowrap' }}>
                  {orderTitle}
                </Typography>
                <NavLink
                  to={orderLink}
                  onClick={() => {
                    handleLinkClick({ orderId: order.id })
                  }}
                >
                  <Typography variant='h6'>#{order.code}</Typography>
                </NavLink>
                <Box sx={{ display: 'flex', alignItems: 'end', marginLeft: 'auto', gap: '4px' }}>
                  <Typography sx={{ fontSize: '.75rem' }}>R$</Typography>
                  <Typography variant='h6' sx={{ display: 'flex' }}>
                    {formatMoney(order.totalValue)}
                  </Typography>
                </Box>
              </Box>
            }
            subtitle={orderSubtitle}
          />
          <LinkToOrder
            to={orderLink}
            onClick={() => {
              handleLinkClick({ orderId: order.id })
            }}
          >
            Ver detalhes
            <Icon icon={faArrowUpRightFromSquare} />
          </LinkToOrder>
        </Card>
      )
    })
  }
  if (!cart) return null

  return (
    <>
      <Accordion
        elevation={0}
        onChange={(_e, isOpen) => setOpen(isOpen)}
        sx={{
          backgroundColor: 'transparent',
          '&:before': {
            display: 'none',
          },
          marginTop: '16px',
        }}
        square
      >
        <AccordionSummary
          expandIcon={<Icon icon={faChevronDown} />}
          sx={(theme: Theme) => ({
            color: theme.palette.primary.main,
            padding: 0,
            '& svg': { color: theme.palette.primary.main },
          })}
        >
          <Typography variant='label3'>{open ? 'Fechar itens do carrinho' : 'Ver itens do carrinho'}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Typography sx={{ fontWeight: 500 }}>Detalhes do carrinho</Typography>
          <Divider sx={{ margin: '22px 0' }} />
          <ValuesSummary
            values={[
              cart.hasRecharge ? { label: 'Pedido de Recarga', value: cart.cartSummary.rechargeValue } : null,
              cart.hasFirstCopy ? { label: 'Pedido de Cartão - 1ª Via', value: cart.cartSummary.firstCopy } : null,
              cart.hasSecondCopy ? { label: 'Pedido de Cartão - 2ª Via', value: cart.cartSummary.secondCopy } : null,
              { label: 'Taxas administrativas', value: cart.cartSummary.administrativeFee, hideZero: true },
              { label: 'Taxas de processamento', value: cart.cartSummary.processingFee, hideZero: true },
              { label: 'Taxa de repasse', value: cart.cartSummary.transferFee, hideZero: true },
              { label: 'Retenção ISS', value: cart.cartSummary.issFee, isNegative: true, color: 'green' },
            ]}
          />
          <Divider sx={{ margin: '22px 0' }} />
          <ValuesSummary
            values={[
              { label: 'Total em pedidos:', value: cart.cartSummary.orderTotal, size: 'md' },
              {
                label: `${account.resale.digitalAccountName}:`,
                value: cart.cartSummary.walletUsage,
                isNegative: true,
                color: 'green',
                size: 'md',
                hideZero: true,
              },
              {
                label: 'Conta corrente total:',
                value: cart.cartSummary.ticketingBalance,
                isNegative: true,
                color: 'green',
                size: 'md',
                hideZero: true,
              },
              {
                label: isWaitingPayment ? 'Total a pagar:' : 'Total pago:',
                value: cart.cartSummary.totalPaid,
                isBold: true,
                size: 'md',
              },
            ]}
          />
        </AccordionDetails>
      </Accordion>
      {cart?.cartOrders.length > 0 && (
        <>
          <Divider sx={{ margin: '9px 0 22px' }} />
          <Typography sx={{ fontWeight: 500 }}>Itens no carrinho</Typography>
        </>
      )}
      {renderCartOrders()}
    </>
  )
}
