import { useState } from 'react'
import { WarnIcon } from './assets/WarnIcon'
import { Plan, SelectPlan, Container, ContainerPlan } from './styles'
import { ModalPlans } from '../modalPlans/modalPlans'
import { planIcon } from '../utils/planIcon'
import { ChangeIcon } from './assets/ChangeIcon'
import { useAtomWithStorageObserver } from '@/main/hooks'
import { AccountPersistentStore } from '@/main/store'

interface PlansProps {
  plan: string
}

export const Plans: React.FC<PlansProps> = ({ plan }) => {
  const [open, setOpen] = useState(false)

  const [account] = useAtomWithStorageObserver({
    atomWithStorage: AccountPersistentStore,
    storageName: 'account',
  })

  const plans = account?.resale?.availablePlans

  return (
    <Container>
      {plan ? (
        <ContainerPlan>
          <Plan>
            {planIcon({ plan })}
            <p>{plan}</p>
          </Plan>
          {plans.length > 1 ? (
            <button onClick={() => setOpen(true)}>
              Alterar <ChangeIcon />
            </button>
          ) : null}
        </ContainerPlan>
      ) : (
        <SelectPlan variant='outlined' onClick={() => setOpen(true)}>
          Selecionar plano <WarnIcon />
        </SelectPlan>
      )}
      <ModalPlans open={open} onClose={() => setOpen(false)} />
    </Container>
  )
}
