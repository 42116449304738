import { styled } from '@mui/material'
import { Accordion, Box } from 'presentation/components/atoms'

export const SummaryContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  width: '100%',
  overflow: 'auto',
}))

export const IconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  minWidth: '40px',
  minHeight: '40px',
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[50],
  '& svg': {
    height: '26px',
    width: '26px',
  },
  '& svg > path': {
    height: '26px',
    width: '26px',
    fill: theme.palette.primary.main,
  },
}))

export const StyledAccordion = styled(Accordion)<{ noMargin: boolean }>(({ theme, noMargin }) => ({
  [theme.breakpoints.up('sm')]: {
    '& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
      marginLeft: '56px',
    },
  },
  '& svg, &  .MuiAccordionSummary-root p': {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  '& .MuiButtonBase-root.MuiAccordionSummary-root': {
    padding: 0,
    gap: '8px',
    justifyContent: 'flex-start',
  },
  '& .MuiAccordionDetails-root': {
    padding: 0,
    paddingRight: '2px',
    margin: noMargin && 0,
  },
  '& .MuiAccordionSummary-content': {
    flexGrow: 'unset',
  },
  '& .MuiCollapse-root': {
    overflow: 'auto',
  },
  '&:before': {
    display: 'none',
  },
}))

export const PaymentDate = styled('small')`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(97, 97, 97, 1);
  position: absolute;
  right: 0;
`
