import { ABBREVIATED_MONTHS } from '@/main/utils/constants'
import {
  ApiOrderPaymentType,
  IApiOrderCount,
  IApiReturn,
  IDashboardMonthlyEarnings,
  IDashboardOrdersStatusCount,
  IUseDashboardReturn,
} from './useDashboardInterfaces'
import { formatMoney } from '@/main/utils/functions'

const monthsAndYear = [...ABBREVIATED_MONTHS, '']

const _getActualMonth = ({ dataToOrganize, index, month }) => {
  return dataToOrganize.find((data) => {
    const monthIndex = Number(String(data.yearMonth).slice(-2)) - 1
    return monthIndex === index || (monthIndex === -1 && month === '')
  })
}

const getActualMonthCount = (orderArray: Array<IApiOrderCount>, monthNumber: number) => {
  return orderArray.find((monthData) => Number(String(monthData.yearMonth).slice(4, 6)) === monthNumber)
}

const mapApiToScreen = (
  ordersCountArray: IApiOrderCount['countStatusViewModel'],
): Array<IDashboardOrdersStatusCount> => {
  if (!ordersCountArray) return [{ orderStatus: null, percentualValue: 0, value: 1 }]
  return ordersCountArray.map((orderCount) => ({
    orderStatus: orderCount.status,
    percentualValue: orderCount.percent,
    value: orderCount.quantity,
  }))
}

const sumOrderCountTypes = ({
  recharge,
  firstCopy,
  secondCopy,
}: {
  recharge: IApiOrderCount
  firstCopy: IApiOrderCount
  secondCopy: IApiOrderCount
}) => {
  const totalSum = recharge?.quantity || 0 + firstCopy?.quantity || 0 + secondCopy?.quantity || 0
  const allOrdersOfMonth = [].concat(
    recharge?.countStatusViewModel,
    firstCopy?.countStatusViewModel,
    secondCopy?.countStatusViewModel,
  )

  const ordersSumByStatus = allOrdersOfMonth.reduce((final, monthOrders) => {
    if (!monthOrders) return final
    const totalOrdersInThisStatus = final[monthOrders.status]?.value + monthOrders.quantity || monthOrders.quantity
    const percentOfTotal = (100 * totalOrdersInThisStatus) / totalSum || 0
    final[monthOrders.status] = {
      orderStatus: monthOrders.status,
      percentualValue: percentOfTotal.toFixed(2),
      value: totalOrdersInThisStatus,
    }
    return final
  }, {})

  return Object.keys(ordersSumByStatus || {}).map((key) => ordersSumByStatus[key])
}
export const getMonthsData = ({ apiData }: { apiData: IApiReturn['paidOrders'] }): Array<IDashboardMonthlyEarnings> => {
  if (!apiData) return null
  return monthsAndYear.map((monthName: string, index) => {
    const actualMonth = _getActualMonth({ dataToOrganize: apiData, index, month: monthName })
    if (!actualMonth)
      return {
        broker: 0,
        dealer: 0,
        month: monthName,
        growthRate: 0,
        total: 0,
      }
    return {
      broker: actualMonth.brokerValue || 0,
      dealer: actualMonth.dealerValue || 0,
      month: monthName,
      growthRate: actualMonth.percentPrevious || 0,
      total: actualMonth.totalValue || 0,
    }
  })
}

export const getOrdersCountsData = ({
  data,
}: {
  data: IApiReturn
}): IUseDashboardReturn['dashboardData']['ordersCounts'] => {
  const {
    countFirstCopy: firstCopy,
    countSecundCopy: secondCopy,
    countRecharge: recharge,
    yearCountFirstCopy: firstCopyYear,
    yearCountSecundCopy: secondCopyYear,
    yearCountRecharge: rechargeYear,
  } = data

  const ordersCounts = monthsAndYear.map((month, index) => {
    const isYear = month === ''
    const selectedRecharges = isYear ? rechargeYear : recharge ? getActualMonthCount(recharge, index + 1) : null
    const selectedFirstCopies = isYear ? firstCopyYear : firstCopy ? getActualMonthCount(firstCopy, index + 1) : null
    const selectedSecondCopies = isYear
      ? secondCopyYear
      : secondCopy
      ? getActualMonthCount(secondCopy, index + 1)
      : null
    const totalRecharges = selectedRecharges?.quantity || 0
    const totalFirstCopies = selectedFirstCopies?.quantity || 0
    const totalSecondCopies = selectedSecondCopies?.quantity || 0
    const totalSum = totalRecharges + totalFirstCopies + totalSecondCopies

    const total = sumOrderCountTypes({
      recharge: selectedRecharges,
      firstCopy: selectedFirstCopies,
      secondCopy: selectedSecondCopies,
    })

    return {
      month: month,
      recharge: mapApiToScreen(selectedRecharges?.countStatusViewModel),
      firstCopy: mapApiToScreen(selectedFirstCopies?.countStatusViewModel),
      secondCopy: mapApiToScreen(selectedSecondCopies?.countStatusViewModel),
      total: total,
      totalSum: totalSum,
      rechargeSum: selectedRecharges?.quantity || 0,
      firstCopySum: selectedFirstCopies?.quantity || 0,
      secondCopySum: selectedSecondCopies?.quantity || 0,
    }
  })
  return ordersCounts
}

export const getOrdersPaymentInfo = ({
  apiData,
}: {
  apiData: IApiReturn
}): IUseDashboardReturn['dashboardData']['monthlyOrdersPaymentInfo'] => {
  if (!apiData.ordesPaymentsType) return null
  const emptyData = {
    type: 'dealer' as any,
    quantity: 0,
    total: 0,
    slipOrdersQuantity: 0,
    slipOrdersPercentual: 0,
    totalSlipValue: 0,
    pixOrdersQuantity: 0,
    pixOrdersPercentual: 0,
    totalPixValue: 0,
    walletOrdersQuantity: 0,
    walletOrdersPercentual: 0,
    totalWalletValue: 0,
  }

  const data = monthsAndYear.map((month: string, index: number) => {
    const getApiMonth = (data) => Number(String(data.yearMonth).slice(4, 6)) === index + 1
    const apiActualMonth = apiData.ordesPaymentsType.find((data) => getApiMonth(data))
    const getDataFromType = (typeData: ApiOrderPaymentType) => ({
      type: typeData.type as any,
      quantity: typeData.orderQuantity,
      total: typeData.orderValue,
      slipOrdersQuantity: typeData.slipOrdersQuantity,
      slipOrdersPercentual: typeData.slipOrdersPercentual,
      totalSlipValue: typeData.totalSlipValue,
      pixOrdersQuantity: typeData.pixOrdersQuantity,
      pixOrdersPercentual: typeData.pixOrdersPercentual,
      totalPixValue: typeData.totalPixValue,
      walletOrdersQuantity: typeData.walletOrdersQuantity,
      walletOrdersPercentual: typeData.walletOrdersPercentual,
      totalWalletValue: typeData.totalWalletValue,
    })

    if (!apiActualMonth && month !== '')
      return {
        month: month,
        data: [
          { ...emptyData, type: 'dealer' as any },
          { ...emptyData, type: 'broker' as any },
        ],
      }

    let typeData = []
    if (month === '') {
      if (!apiData.ordesPaymentTypeForYear) {
        return {
          month: '',
          data: [
            { ...emptyData, type: 'dealer' as any },
            { ...emptyData, type: 'broker' as any },
          ],
        }
      }
      typeData = [
        getDataFromType(apiData.ordesPaymentTypeForYear?.dealer),
        getDataFromType(apiData.ordesPaymentTypeForYear?.broker),
      ]
    } else typeData = apiActualMonth.ordesPaymentTypeSumType.map((monthInfo) => getDataFromType(monthInfo))

    return {
      month: month,
      data: typeData,
    }
  })
  return data
}

export const getBalanceProjectionInfo = ({
  apiData: dataToOrganize,
}): IUseDashboardReturn['dashboardData']['balanceProjectionInfo'] => {
  if (!dataToOrganize) return null
  const dataToReturn = {
    averageSave: [],
    ordersWithProjection: [],
    lastMonthEconomy: [],
  }

  monthsAndYear.forEach((month, index) => {
    const actualMonth = _getActualMonth({ dataToOrganize, index, month })
    dataToReturn.averageSave.push({
      month: month,
      value: actualMonth?.percentageSavings || 0,
      percentualOverLastMonth: actualMonth?.averageVariablePercent || 0,
    })
    dataToReturn.lastMonthEconomy.push({
      month: month,
      value: actualMonth?.amount || 0,
      percentualOverLastMonth: actualMonth?.amountVariablePercent || 0,
    })
    dataToReturn.ordersWithProjection.push({
      month: month,
      value: actualMonth?.quantity || 0,
      percentualOverLastMonth: actualMonth?.quantityVariablePercent || 0,
    })
  })

  return dataToReturn
}

export const getResaleFinancialInfo = ({ apiData: dataToOrganize }: { apiData?: IApiReturn['paidOrders'] }) => {
  if (!dataToOrganize) return null
  return monthsAndYear.map((month, index) => {
    const actualMonth = _getActualMonth({ dataToOrganize, index, month })

    return {
      month,
      data: [
        {
          indicatorName: 'Valor em Pedidos',
          valueType: 'Bruto R$',
          total:
            typeof actualMonth?.totalValue === 'number' ? 'R$ ' + formatMoney(actualMonth?.totalValue) : 'Indisponível',
        },
        {
          indicatorName: 'Receita',
          valueType: 'Liquida R$',
          total:
            typeof actualMonth?.financialRevenue === 'number'
              ? 'R$ ' + formatMoney(actualMonth.financialRevenue)
              : 'Indisponível',
        },
        {
          indicatorName: 'Taxa de Sucesso',
          valueType: '% variável',
          total:
            typeof actualMonth?.sucessFeeValue === 'number'
              ? 'R$ ' + formatMoney(actualMonth.sucessFeeValue)
              : 'Indisponível',
        },
        {
          indicatorName: 'Taxa Adm',
          valueType: '% variável',
          total:
            typeof actualMonth?.administratefee === 'number'
              ? 'R$ ' + formatMoney(actualMonth.administratefee)
              : 'Indisponível',
        },
        {
          indicatorName: 'Taxa média',
          valueType: 'Vol. Vendas/Receita',
          total: typeof actualMonth?.averageValue === 'number' ? actualMonth.averageValue + ' %' : 'Indisponível',
        },
      ],
    }
  })
}

export const getFinancialStation = ({ apiData: dataToOrganize }: { apiData?: IApiReturn['contractualBillings'] }) => {
  if (!dataToOrganize)
    return [
      {
        month: 'Jan',
        data: [
          {
            indicatorName: 'Dealer',
            valueType: '0.10%',
            total: 'Indisponível',
          },
        ],
      },
    ]
  return monthsAndYear.map((month, index) => {
    const actualMonth = _getActualMonth({ dataToOrganize, index, month })

    return {
      month,
      data: [
        {
          indicatorName: 'Dealer',
          valueType: '0.10%',
          total:
            typeof actualMonth?.dealerBillingValue === 'number'
              ? 'R$ ' + formatMoney(actualMonth?.dealerBillingValue)
              : 'Indisponível',
        },
        {
          indicatorName: 'Broker',
          valueType: '0.30%',
          total:
            typeof actualMonth?.brokerBillingValue === 'number'
              ? 'R$ ' + formatMoney(actualMonth?.brokerBillingValue)
              : 'Indisponível',
        },
        {
          indicatorName: 'Remuneração SK',
          valueType: 'Até R$ 10K',
          total:
            typeof actualMonth?.compensationAmount === 'number'
              ? 'R$ ' + formatMoney(actualMonth.compensationAmount)
              : 'Indisponível',
        },
        {
          indicatorName: 'Hospedagem',
          valueType: 'Fixo',
          total:
            typeof actualMonth?.hostingValue === 'number'
              ? 'R$ ' + formatMoney(actualMonth.hostingValue)
              : 'Indisponível',
        },
        {
          indicatorName: 'Total',
          valueType: '',
          total:
            typeof (actualMonth?.hostingValue + actualMonth?.compensationAmount) === 'number'
              ? 'R$ ' + formatMoney(actualMonth?.hostingValue + actualMonth?.compensationAmount)
              : 'Indisponível',
        },
      ],
    }
  })
}
