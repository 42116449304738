import { useContext } from 'react'
import { Theme } from '@mui/material'
import { Card, Divider, Typography } from 'presentation/components/atoms'
import { PaymentTypeCard } from './components/paymentTypeCard/paymentTypeCard'
import { CartInfo } from './components/cartInfo/cartInfo'
import { OrderTrackingContext } from '../../orderTracking'
import { ORDER_STATUS } from 'main/utils/constants'
import { ValuesSummary } from 'presentation/components/molecules'
import { useAtomWithStorageObserver, useFeatures } from 'main/hooks'
import { DestinationAddressCard } from './components/destinationAddressCard/destinationAddressCard'
import { ORDER_TYPES } from 'main/utils/constants'
import { AccountPersistentStore } from '@/main/store'

export const PaymentDetail = () => {
  const { features } = useFeatures()
  const [account] = useAtomWithStorageObserver({ atomWithStorage: AccountPersistentStore, storageName: 'account' })

  const {
    cart,
    orderCode,
    orderDate,
    orderType,
    orderSummary,
    orderStatus,
    usedPaymentsTypes,
    destinationAddress,
    isRecharge,
    slip,
    isFinancedSales,
    paymentDate,
  } = useContext(OrderTrackingContext)
  const isWaitingPayment = orderStatus < ORDER_STATUS.WAITING_FOR_PROCESSING && !usedPaymentsTypes.includes('FREE')

  let orderTypeLabel = 'Pedido'
  if (orderType === ORDER_TYPES.FIRST_COPY) orderTypeLabel += ' 1ª via'
  else if (orderType === ORDER_TYPES.SECOND_COPY) orderTypeLabel += ' 2ª via'

  const getTotalLabel = (): string => {
    if ([ORDER_STATUS.CANCELED, ORDER_STATUS.CANCELING].includes(orderStatus)) return 'Total:'
    return isWaitingPayment ? 'Total a pagar:' : 'Total pago:'
  }

  return (
    <Card sx={(theme: Theme) => ({ backgroundColor: theme.palette.grey[50], padding: '16px' })}>
      <Typography variant='h4'>Detalhes do pedido</Typography>
      <Typography
        variant='label2'
        sx={(theme: Theme) => ({
          fontWeight: 400,
          color: theme.palette.grey[700],
          fontStyle: 'italic',
        })}
      >
        {cart && `Item do carrinho #${cart.code} ⚬`} {orderTypeLabel} #{orderCode} ⚬ {orderDate}
      </Typography>
      <Divider sx={{ margin: '22px 0' }} />
      <ValuesSummary
        values={[
          { label: isRecharge ? 'Valor da recarga' : 'Valor do pedido', value: orderSummary.orderValue },
          { label: 'Taxa administrativa', value: orderSummary.administrativeFee, hideZero: true },
          { label: 'Taxa de processamento', value: orderSummary.processingFee, hideZero: true },
          { label: 'Taxa de repasse', value: orderSummary.transferFee, hideZero: true },
          { label: 'Retenção ISS', value: orderSummary.issFee, color: 'green', isNegative: true },
        ]}
      />
      <Divider sx={{ margin: '22px 0' }} />
      <ValuesSummary
        values={[
          orderSummary.orderTotal === orderSummary.totalPaid
            ? null
            : { label: 'Total do pedido:', value: orderSummary.orderTotal, size: 'md', hideZero: true },
          features.ticketingBalance
            ? {
                label: 'Conta corrente:',
                value: orderSummary.ticketingBalance,
                size: 'md',
                color: 'green',
                isNegative: true,
                hideZero: true,
              }
            : null,
          {
            label: `${account.resale.digitalAccountName}:`,
            value: orderSummary.walletUsage,
            size: 'md',
            color: 'green',
            isNegative: true,
            hideZero: true,
          },
          {
            label: getTotalLabel(),
            value: orderSummary.totalPaid,
            size: 'md',
            color: 'black',
            isBold: true,
          },
        ]}
      />
      <Divider sx={{ margin: '22px 0' }} />
      <Typography sx={{ fontWeight: 500, marginBottom: '8px' }}>Pagamento</Typography>
      <PaymentTypeCard
        paymentDate={paymentDate}
        isFinancedSales={isFinancedSales}
        type={usedPaymentsTypes}
        status={orderStatus}
        slipDueDate={slip?.slipDueDate}
      />
      {!isRecharge && destinationAddress && (
        <>
          <Typography sx={{ fontWeight: 500, margin: '22px 0px 8px' }}>Entrega</Typography>
          <DestinationAddressCard />
        </>
      )}
      {cart?.id && <CartInfo />}
    </Card>
  )
}
