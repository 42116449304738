import { styled } from '@mui/material'
import { Box, Button, Divider } from '@stationkim/front-ui'
import { Accordion } from './components/accordion/accordion'

export const StyledDivider = styled(Divider)(() => ({ margin: '20px 0' }))

export const StyledButton = styled(Button, { shouldForwardProp: (propName) => propName !== 'visible' })<{
  visible: boolean
}>(({ visible }) => {
  let style: any = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    overflow: 'hidden',
    transition: 'all .15s linear',
  }

  if (!visible) {
    style = {
      ...style,
      width: 0,
      minWidth: 0,
      padding: 0,
    }
  }

  return style
})

export const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
}))

export const StyledAccordion = styled(Accordion)(() => ({
  '& .MuiAccordionDetails-root': {
    marginBottom: '12px',
  },
}))
