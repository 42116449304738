import { styled } from '@mui/material'
import { Box, Typography } from '@stationkim/front-ui'

export const StyledTypography = styled(Typography)(() => ({
  marginBottom: '16px',
}))

export const StyledLabel = styled('label')(() => ({
  display: 'flex',
  gap: '8px',
  width: 'fit-content',
  alignItems: 'center',
  cursor: 'pointer',
  '& p': {
    fontWeight: 700,
    lineHeight: '16px',
  },
}))

export const StyledISSContainer = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'visible',
})<{ visible: boolean }>(({ visible }) => {
  return {
    maxHeight: visible ? 'initial' : '0px',
    padding: visible ? '8px 0' : '0',
    transition: 'all .25s ease-out',
    overflow: 'hidden',
  }
})
