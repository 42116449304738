import { styled } from '@mui/material'
import { Button } from '@stationkim/front-ui'

export const ButtonIcon = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'color',
})<{ color?: 'primary' | 'error' }>(({ theme, color = 'primary' }) => ({
  backgroundColor: theme.palette[color].main + '3D',
  color: theme.palette[color].dark,
  padding: '4px',
  borderRadius: '4px',
  height: '24px',
  width: '24px',
  minWidth: 'unset',
  cursor: 'pointer',
  transition: 'all 0.15s linear',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  '&:active': {
    transform: 'scale(.9)',
  },
  '&:last-of-type': {
    marginLeft: '8px',
  },
  '& svg': {
    fontSize: '.875rem',
  },
}))
