import { faPix } from '@fortawesome/free-brands-svg-icons'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faBarcode, faHandHoldingDollar, faWallet } from '@fortawesome/free-solid-svg-icons'

export const docIcons = ({
  paymentType,
}: {
  paymentType: 'Boleto' | 'Pix' | 'Carteira Digital' | 'Gratuito' | 'Boleto a Prazo'
}) => {
  switch (paymentType) {
    case 'Boleto a Prazo': {
      return faCalendar
    }
    case 'Boleto': {
      return faBarcode
    }
    case 'Pix': {
      return faPix
    }
    case 'Carteira Digital': {
      return faWallet
    }
    case 'Gratuito': {
      return faHandHoldingDollar
    }
  }
}
