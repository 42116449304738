// todo move to front-ui
import { AccordionProps as MUIAccordionProps, AccordionDetails, AccordionSummary, SxProps } from '@mui/material'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Icon, Typography } from '@stationkim/front-ui'
import { StyledAccordion } from './styles'

export interface AccordionProps extends MUIAccordionProps {
  label?: string | React.ReactNode
  detailsStyle?: SxProps
  summaryStyle?: SxProps
}

export const Accordion = ({
  children,
  label,
  disableGutters = true,
  elevation = 0,
  summaryStyle = { padding: 0 },
  detailsStyle = { padding: 0 },
  ...props
}: AccordionProps) => {
  return (
    <StyledAccordion {...props} disableGutters={disableGutters} elevation={elevation}>
      <AccordionSummary expandIcon={<Icon icon={faChevronDown} />} sx={summaryStyle}>
        {typeof label === 'string' ? <Typography variant='h4'>{label}</Typography> : label}
      </AccordionSummary>
      <AccordionDetails sx={detailsStyle}>{children}</AccordionDetails>
    </StyledAccordion>
  )
}
